<template>
	<img class="app-logo" :alt="brandName" :src="logo" />
</template>

<script>
export default {
	name: 'AffiliateLogo',
	props: {
		logo: String,
	},
};
</script>
<style lang="scss">
.app-logo {
	width: 100%;
	height: auto;
	max-height: 41px;
	object-fit: contain;
}
</style>
