<template>
	<PageLayout v-if="error !== null" hide-back-button>
		<div class="card">
			<div class="container">
				<Spacer size="xl" height="true" />
				<img class="container-img" :src="require('../../assets/icons/error.png')" />
				<Spacer size="xl" height="true" />
				<h1 class="container-header">{{ $t('global.error') }}</h1>
				<span class="container-label">{{ $t(errorMessage) }}</span>
			</div>
			<Spacer size="xl" height="true" />
			<div v-if="!isEmbedded" class="buttons">
				<div class="buttons-content">
					<CButton secondary full-width @click="navigateToDashboard()">
						{{ $t('actions.back-to-dashboard') }}
					</CButton>
				</div>
			</div>
		</div>
	</PageLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import CButton from '@/shared/cbutton/CButton';
import PageLayout from '@/components/page-layout/PageLayout';
import { extractErrorMessage } from '@/utils/errorHandling';
import Spacer from '@/shared/spacer/Spacer';

export default {
	name: 'ErrorCard',
	components: {
		PageLayout,
		CButton,
		Spacer,
	},
	props: {
		error: {
			type: String,
			default: null,
		},
	},
	computed: {
		...mapGetters(['getUserError', 'isEmbedded']),
		errorMessage() {
			if (this.getUserError) {
				return this.$t('errors.cannot-fetch-user-details');
			}

			return extractErrorMessage(this.error);
		},
	},
	methods: {
		navigateToDashboard() {
			window.location.href = '/';
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';
.card {
	@include card;
	flex-grow: 1;
	min-width: 100%;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	&-header {
		font-size: 30px;
		margin: 0px;
	}

	&-label {
		font-size: $label-md;
		color: $white;
		opacity: 0.5;
	}

	&-img {
		width: 108px;
	}
}

.buttons {
	display: flex;
	justify-content: center;

	&-content {
		display: flex;
		justify-content: center;
		gap: 5px;
		width: 60%;

		@include max-screen($md) {
			flex-direction: column;
			width: 100%;
		}
	}
}
</style>
