<template>
	<div class="stat__item balance">
		<Spinner v-if="loading" />
		<template v-if="!loading">
			<div class="prepend">
				<a class="visibility-toggler" @click="toggleVisibility">
					<img v-if="balanceVisible" src="../../assets/eye.svg" alt="eye" />
					<img v-if="!balanceVisible" src="../../assets/eye_opened.svg" alt="eye_opened" />
				</a>
			</div>
			<div class="labelDataWrapper">
				<div class="labelTinyAppendWrapper">
					<span class="label">{{ $t('dashboard.balance') }}</span>
				</div>
				<div class="dataWrapper">
					<span class="data">{{
						balance !== null && balanceVisible
							? Intl.NumberFormat($i18n.locale, { style: 'currency', currency: currency }).format(balance)
							: '***'
					}}</span>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import Spinner from '@/shared/spinner/Spinner';
import apiClient from '../../api';

export default {
	name: 'UserBalance',
	components: { Spinner },
	data() {
		return {
			balance: null,
			currency: null,
			balanceVisible: true,
			loading: false,
		};
	},
	created() {
		this.fetchBalance();
	},
	methods: {
		async fetchBalance() {
			try {
				this.loading = true;
				const data = await apiClient.fetchUserBalance();
				this.balance = Number(data.balance) + Number(data.credit);
				this.currency = data.currency;
			} catch (e) {
				console.error(e);
			} finally {
				this.loading = false;
			}
		},
		toggleVisibility(e) {
			e.preventDefault();
			this.balanceVisible = !this.balanceVisible;
		},
	},
};
</script>

<style scoped lang="scss">
.stat__item {
	display: flex;
	padding: 0.5rem 0.8rem;
	align-items: center;
	background: rgba(42, 46, 49, 0.5);
	border: 1px solid #2a2e31;
	border-radius: 6px;
	min-width: fit-content;
	height: 60px;
	flex: 1;

	.prepend {
		margin-right: 0.8rem;
	}

	.visibility-toggler {
		display: flex;
		justify-content: center;
		align-content: center;
	}

	.labelTinyAppendWrapper {
		display: flex;
	}

	.labelDataWrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.dataWrapper {
			display: flex;
		}
	}

	span.label {
		font-size: 12px;
		color: rgba(255, 255, 255, 0.5);
		text-align: start;
	}

	span.data {
		font-size: 16px;
		font-weight: bold;
		color: white;
		text-align: start;
	}
}

@media screen and (max-width: 359px) {
	.stat__item {
		.prepend {
			display: none;
		}
	}
}
</style>
