// This function returns the path to the highest priority pending onboarding step, returning only one path at a time
export const getPathForNextOutstandingOnboardingStep = (pendingSteps) => {
	if (pendingSteps.length < 1) {
		return null;
	}
	return getPathForOutstandingOnboardingStep(pendingSteps[0].step);
};

const getPathForOutstandingOnboardingStep = (pendingStep) => {
	switch (pendingStep) {
		case 'CompleteAccountBeforeDepositForm':
			return '/payments/deposit/form';
		case 'CompleteAccountBeforeWithdrawForm':
			return '/payments/withdrawals/form';
		case 'EmailConfirmation':
			return '/confirm-email';
		case 'PhoneConfirmation':
			return '/confirm-phone';
		case 'KYCManual':
			return '/documents/upload';
		case 'KYCSumSub':
			return '/identity-verification';
		case 'DisclaimerBeforeDeposit':
			return '/disclaimer';
		default:
			return null;
	}
};
