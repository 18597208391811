export const extractErrorMessage = (error) => {
	if (!error?.message) {
		return 'Unknown error';
	}

	if (!error.response) {
		return error.message;
	}

	if (error.response.statusText) {
		return error.response.statusText;
	}

	if (error.response.data.error) {
		return error.response.data.error.message;
	}

	if (error.response?.data?.error) {
		if (error.response.data.error.message) {
			if (typeof error.response.data.error.message !== 'string') {
				return 'Unknown error';
			}
		}
		return error.response.data.error.message || error.response.data.error;
	}

	if (error.response?.data?.errors && Array.isArray(error.response.data.errors)) {
		return error.response.data.errors[0].msg;
	}

	return error.message;
};
